
.align-center {
    align-items: center;			
  }
  .container-fluid {
    align-items: center !important;
    margin-top: 7vw;
    text-align: left;
  }
  .color-white{fill:#FFFFFF;}	
   .maintenanceIcon{
       height: 100%;
       width: 100%;
   } 
  .constructionSubHead{
      font-family: PoppinsMedium;
  }
  h1 {
    margin: 0;
    font-size: 5vmax;
      font-size: 36px;
      font-size: 7vmin;
    color:rgba(0,0,0,.4);
    // font-family: PoppinsBold;
  }	
  
  .st0 {
    fill: #FFCB1E;
    -webkit-animation: upMotion 6s ease-in-out infinite;
    -moz-animation: upMotion 6s ease-in-out infinite;
    animation: upMotion 6s ease-in-out infinite;
  }
  
  .st1 {
    fill: #FFCB1E;
    stroke: #161616;
    stroke-width: 3;
    stroke-miterlimit: 10;
    
  }
  
  .st2 {
    fill: #161616;
    
  }
  
  .st3 {
    fill: #EC615B;
   
  }
  
  .st4 {
    opacity: 0.4;
    fill: #3D3D3D;
  }
  
  .st5 {
    opacity: 0.38;
    fill: #3D3D3D;
  }
  
  .st6 {
    opacity: 0.42;
    fill: #3D3D3D;
  }
  
  .st7 {
    fill: none;
    stroke: #161616;
    stroke-width: 16;
    stroke-miterlimit: 10;
  }
  
  .st8 {
    fill: #FFB8B8;
  }
  
  .st9 {
    fill: none;
    stroke: #161616;
    stroke-width: 3;
    stroke-miterlimit: 10;
    
  }
  
  .st10 {
    opacity: 0.37;
    fill: #3D3D3D;
  }
  
  .st11 {
    opacity: 0.5;
    fill: #3D3D3D;
  }
  
  .st12 {
    opacity: 0.32;
    fill: #3D3D3D;
  }
  
  .st13 {
    opacity: 0.29;
    fill: #3D3D3D;
  }
  
  .st14 {
    fill: #FFFFFF;
  }
  
  .st15 {
    opacity: 0.48;
    fill: #3D3D3D;
  }
  
  .st16 {
    opacity: 0.27;
    fill: #3D3D3D;
  }
  
  .st17 {
    opacity: 0.36;
    fill: #3D3D3D;
  }
  
  .st18 {
    opacity: 0.43;
    fill: #3D3D3D;
  }
  
   @keyframes downMotion {
     0% {transform: translate(0, -20px);}
     50% {transform: translate(0, 0); }
     100% {transform: translate(0, -20px);}
    }
   @-webkit-keyframes downMotion {
     0% {transform: translate(0, -20px);}
     50% {transform: translate(0, 0); }
     100% {transform: translate(0, -20px);}
    }
   @-moz-keyframes downMotion {
     0% {transform: translate(0, -20px);}
     50% {transform: translate(0, 0); }
     100% {transform: translate(0, -20px);}
    } 		  		 
   @keyframes upMotion {
     0% {transform: translate(0, 25px);}
     50% {transform: translate(0, 0); }
     100% {transform: translate(0, 25px);}
    } 
   @-webkit-keyframes upMotion {
     0% {transform: translate(0, 25px);}
     50% {transform: translate(0, 0); }
     100% {transform: translate(0, 25px);}
    }  
   @-moz-keyframes upMotion {
     0% {transform: translate(0, 25px);}
     50% {transform: translate(0, 0); }
     100% {transform: translate(0, 25px);}
    }   		 		 
                    
  @media only screen and (min-width: 992px) {
    .container {
      justify-content: center;
      height: calc(100vh - 16px);
      display: flex;
      flex-wrap: wrap;
    }			
    
  }