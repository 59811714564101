.imgTop {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.industy-headsection {
  max-width: 1200px;
  font-family: PoppinsMedium;
  padding: 90px 0 20px;
  margin: 0 auto;
}
