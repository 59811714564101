.footer {
    background-color: #333333;
    color: white;

    .fsection {
        max-width: 1200px;
        margin: 0 auto;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        h3 {
            font-family: PoppinsBold;
            font-size: var(--subhead);
            margin: 0px;
        }
        .fsection-ilink {
            align-self: flex-start;

            .logo {
                height: clamp(25px, 9vw, 50px);
                margin: 2rem 0;
            }
        }
        .fInfo {
            display: grid;
            grid-template-areas: "fcontact qLink loc";
            grid-gap: 2rem;
            text-align: left;
            .footercontact {
                grid-area: fcontact;
                display: flex;
                flex-direction: column;
                gap: 2rem;
                .cIcon {
                    display: flex;
                    justify-content: space-between;
                    max-width: 250px;
                    img {
                        height: clamp(10px, 7vw, 30px);
                    }
                }
                .cNo {
                    font-size: var(--subp);
                    display: grid;
                    grid-template-columns: 1fr 3fr;
                    text-align: left;
                    max-width: 250px;
                    img {
                        height: clamp(10px, 6vw, 30px);
                    }
                    p {
                        align-self: center;
                        justify-self: flex-start;
                        a {
                            font-size: clamp(8px, 3vw, 14px);
                            color: white;
                            text-decoration: none;
                        }
                    }
                }
            }
            .quicklink {
                grid-area: qLink;
                display: flex;
                flex-direction: column;
                gap: 2rem;
                .groupLink {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    a {
                        color: white;
                        text-decoration: none;
                    }
                    p {
                        font-size: clamp(10px, 5vw, 14px);
                    }
                }
            }
            .location {
                grid-area: loc;
                font-size: 14px;
                display: flex;
                flex-direction: column;
                gap: 2rem;
                .laddres {
                    max-width: 250px;
                    p {
                        font-size: clamp(10px, 5vw, 14px);
                    }
                    .head {
                        font-size: clamp(10px, 5vw, 14px);
                        font-family: PoppinsBold;
                        margin-bottom: 0.5rem;
                    }
                }
            }
        }
        .copyright {
            display: flex;
            justify-content: space-between;
            p {
                font-size: clamp(8px, 2vw, 14px);
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .fInfo {
        grid-template-areas: "fcontact" "loc" !important;
        .quicklink {
            display: none !important;
        }
    }
}
@media screen and (max-width: 430px) {
    .fInfo {
        grid-template-areas: "fcontact" !important;
        .location {
            display: none !important;
        }
    }
}
@media screen and (max-width: 1202px) {
    .footer {
        padding: 0 3vw !important;
    }
}
