@media screen and (max-width:1200px) {
    .caseStudy {
        padding: 0 3vw !important;
    }
}
@media screen and (max-width: 950px) {
    .casebodySection {
        flex-direction: column;
    }
}

.caseStudy {
    min-height: 100vh;
    color: #383f50;
    .caseHeadSection {
        max-width: 1200px;
        font-family: PoppinsMedium;
        padding: 90px 0 20px;
        margin: 0 auto;
        .logo {
            height: 90px;
            margin-bottom: 10px;
            img {
                height: 100%;
            }
        }
    }
    .casebodySection {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        text-align: left;
        gap: 2rem;
        h4 {
            font-size: var(--casehead);
            font-family: PoppinsMedium;
        }
        p {
            font-family: PoppinsMedium;
            font-size: var(--casep);
        }
        .casedetails {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
        .prototype {
            height: clamp(200px, 50vw, 400px);
            justify-self: center;
            align-self: center;
            img {
                height: 100%;
            }
        }
    }
    .pagination {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        gap: 2rem;
        justify-content: flex-end;
        button {
            color: black;
            font-family: PoppinsMedium;
            background-color: rgba(0, 0, 0, 0);
            border: none;
            margin: 1rem 0 2rem;
            transition: color 0.3s ease-In-Out;
            font-size: clamp(0.8rem, 3vw, 1.1rem);
        }

        button:hover {
            color: #cc4d54;
        }
    }
    .view {
        animation: fade-in-keyframes 0.8s ease-in;
    }
    @keyframes fade-in-keyframes {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .viewBody {
        position: relative;
        animation: animBody 0.8s ease-in;
    }
    @keyframes animBody {
        from {
            opacity: 0;
            top: 20px;
        }
        to {
            opacity: 1;
            top: 0px;
        }
    }
    .viewpagination {
        animation: animPagination 0.8s ease-in;
    }
    @keyframes animPagination {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
