.FormSection {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 90px 0 20px;
    .headingUnderline {
        max-width: 200px;
        margin: 0 auto;
        height: 2px;
        opacity: 1;
    }
    h2 {
        font-family: PoppinsMedium;
        font-size: var(--sech2) !important;
    }
    .form {
        max-width: 1000px;
        overflow-x: hidden;
        display: flex;
        margin: 0 auto;
        padding-bottom: 2rem;
        min-height: 70vh;
        flex-direction: column;
        gap: 3rem;
        justify-content: center;
        width: 100%;
        .error {
            border-bottom: 1px solid red;
            transition: border-bottom 0.3s ease-in-out;
        }
        .error::placeholder {
            color: red;
            transition: color 0.3s ease-in-out;
            opacity: 1;
        }

        .error:-ms-input-placeholder {
            transition: color 0.3s ease-in-out;
            color: red;
        }

        .error::-ms-input-placeholder {
            transition: color 0.3s ease-in-out;
            color: red;
        }
        input {
            font-family: Poppins;
            border: none;
            border-bottom: 1px solid #c4c4c4;
            font-size: clamp(10px, 5vw, 14px);
        }
        textarea {
            font-family: Poppins;
            border: none;
            border-bottom: 1px solid #c4c4c4;
            font-size: clamp(10px, 5vw, 14px);
        }

        textarea:focus,
        input:focus {
            outline: none;
        }
        .fgroup {
            align-self: flex-start;
            .privacypolicy {
                color: var(--orange);
            }
            label {
                font-size: clamp(10px, 5vw, 14px);
            }
        }
        .submitbtn {
            background-color: black;
            color: white;
            font-family: Poppins;
            font-size: clamp(12px, 7vw, 18px);
            border: none;
            padding: 0.3rem 1.3rem;
            align-self: center;
        }
    }
}
@media screen and (max-width: 400px) {
    .headingUnderline {
        max-width: 150px !important;
    }
}
@media screen and (max-width: 1005px) {
    .FormSection {
        .form {
            padding-right: 3vw;
            padding-left: 3vw;
        }
    }
}
