p {
    font-family: PoppinsMedium;
    font-size: var(--para);
}
.homeCarousel {
    .carousel-indicators {
        z-index: 1 !important;
        .active {
            background-color: var(--orange);
        }
    }
    .carousel-item {
        img {
            height: 100vh;
            object-fit: cover;
        }
    }
    .carousel-control-next,
    .carousel-control-prev {
        width: 10% !important;
        padding: 25px 3% !important;
        align-items: flex-end !important;
    }
    .carousel-caption {
        top: 39%;
        h3 {
            font-family: PoppinsLight;
            font-size: clamp(1rem, 6vw, 3rem) !important;
            text-shadow: 0px 0px 8px #363636;
        }
        h2 {
            font-family: PoppinsBold;
            text-shadow: 0px 0px 8px #363636;
            font-size: clamp(1.2rem, 6vw, 3.4rem) !important;
        }
        .btns {
            .button {
                border-radius: 0px;
                padding: 0.7rem 2rem;
                font-family: PoppinsMedium;
                font-size: clamp(6px, 4vw, 18px) !important;
                width: clamp(180px, 4vw, 450px);
            }
            .light {
                margin-right: 1rem;
            }
            .orange {
                background-color: var(--orange);
                border-color: var(--orange) !important;
            }
            .orange:hover {
                background-color: rgba(255, 255, 255, 100);
                border-color: rgba(255, 255, 255, 100) !important;
                color: black;
            }
            .btn {
                transition: 0.5s ease-in-out;
            }
        }
    }
}
.aboutSection {
    display: flex;
    gap: 2rem;
    text-align: left;
    align-items: center;
    margin: 3rem auto;
    max-width: 1200px;
    img {
        max-height: 550px;
    }
    .abouttexts {
        .head {
            color: var(--orange);
            font-family: PoppinsBold;
            font-size: var(--phead);
            margin-bottom: 0.5rem;
        }
        h3 {
            padding: 0.3rem 0;
            font-family: PoppinsBold;
            font-size: var(--h3);
        }
        p {
            font-family: PoppinsMedium;
            font-size: var(--para);
        }
        .button {
            background-image: linear-gradient(to right, var(--orange), #b93a11, #b93a11, #b93a11);
            color: white;
            background-size: 200%;
            background-position: left;
            transition: 0.8s background-position ease-in-out;
            font-family: PoppinsMedium;
            font-size: var(--button);
            border: none;
            padding: 0.3rem 1rem;
        }
    }
}

.serviceSection {
    background-image: url("https://media.pennonn.com/PennonnWebFiles/images/home/serviceImg.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    min-height: 50vh;
    display: flex;
    align-items: center;
    padding: 5% 0px;
    .section {
        max-width: 1200px;
        margin: 0 auto;
        color: white;
        display: flex;
        flex-direction: column;
        gap: 5rem;
        .abouttexts {
            text-align: left;
            width: 50%;
            .head {
                color: var(--orange);
                font-family: PoppinsBold;
                font-size: var(--phead);
            }
            h3 {
                font-family: PoppinsBold;
                font-size: var(--h3);
            }
            p {
                font-size: var(--para);
                font-family: PoppinsMedium;
            }
            .button {
                background-image: linear-gradient(to right, var(--orange), #b93a11, #b93a11, #b93a11);
                color: white;
                background-size: 200%;
                background-position: left;
                transition: 0.8s background-position ease-in-out;
                font-family: PoppinsMedium;
                font-size: var(--button);
                border: none;
                padding: 0.3rem 1rem;
            }
        }
        .svcIcons {
            display: grid;
            justify-self: center;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            .Igroup {
                max-width: 300px;
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 5px;
                img {
                    width: 100px;
                }
                p {
                    font-size: var(--subp);
                }
            }
        }
    }
}
.project {
    display: flex;
    gap: 2rem;
    text-align: left;
    align-items: center;
    margin-top: 3rem;
    max-width: 1200px;
    img {
        max-height: 550px;
    }
    .abouttexts {
        .head {
            color: var(--orange);
            font-family: PoppinsBold;
            font-size: var(--phead);
            margin-bottom: 0.5rem;
        }
        h3 {
            padding: 0.3rem 0;
            font-family: PoppinsBold;
            font-size: var(--h3);
        }
        p {
            font-size: var(--para);
            font-family: PoppinsMedium;
        }
        .button {
            background-image: linear-gradient(to right, var(--orange), #b93a11, #b93a11, #b93a11);
            color: white;
            background-size: 200%;
            background-position: left;
            transition: 0.8s background-position ease-in-out;
            font-family: PoppinsMedium;
            font-size: var(--button);
            border: none;
            padding: 0.3rem 1rem;
        }
    }
}

@media screen and (max-width: 350px) {
    .serviceSection {
        .section {
            .svcIcons {
                grid-template-columns: 1fr !important ;
            }
        }
    }
}
.testimonial {
    background-image: url("https://media.pennonn.com/PennonnWebFiles/images/home/testimonial.jpg");
    background-size: cover;
    background-position: bottom center;
    background-attachment: fixed;
    color: white;
    .skewed {
        background-color: #2b2b2894;
        max-width: 1200px;
        clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
        width: 60vw;
        left: -80px;
        text-align: left;
        padding: 0 6vw;
        .abouttexts {
            padding: 3rem 0;
            .head {
                color: var(--orange);
                font-family: PoppinsBold;
                font-size: var(--phead);
            }
            h3 {
                font-family: PoppinsBold;
                font-size: var(--h3);
            }
            p {
                font-family: PoppinsMedium;
                font-size: var(--para);
            }
            .button {
                background-image: linear-gradient(to right, var(--orange), #b93a11, #b93a11, #b93a11);
                color: white;
                background-size: 200%;
                background-position: left;
                transition: 0.8s background-position ease-in-out;
                font-family: PoppinsMedium;
                font-size: var(--button);
                border: none;
                padding: 0.3rem 1rem;
            }
            .carousel {
                margin: 2rem 1.5rem 2rem 0;
                h3 {
                    font-size: var(--subhead);
                }
                p {
                    font-size: 14px;
                    font-size: var(--subp);
                }
                .tCarousel {
                    p {
                        font-size: var(--para);
                    }
                }
            }
            .carousel-control-prev {
                visibility: hidden;
            }
            .carousel-control-next {
                visibility: hidden;
            }
            .carousel-indicators {
                margin-bottom: 0;
                bottom: -1rem;
                z-index: 1 !important;
                .active {
                    background-color: var(--orange);
                }
            }
        }
    }
}
.smoke {
    background-color: #2b2b28a2;
}
.contact {
    background-image: url("https://media.pennonn.com/PennonnWebFiles/images/home/contact.jpg");
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    color: white;
    .section {
        display: flex;
        max-width: 1200px;
        margin: 0 auto;
        padding: 2rem 0;
        gap: 2rem;
        align-items: center;
        .abouttexts {
            padding: 3rem 0;
            color: white;
            text-align: left;
            .head {
                color: var(--orange);
                font-family: PoppinsBold;
                font-size: var(--phead);
            }
            h3 {
                font-family: PoppinsBold;
                font-size: var(--h3);
            }
            p {
                font-family: PoppinsMedium;
                font-size: va;
                a {
                    color: var(--orange);
                    text-decoration: none;
                }
                a:hover {
                    text-decoration: underline;
                }
            }
            .button {
                background-image: linear-gradient(to right, var(--orange), #b93a11, #b93a11, #b93a11);
                color: white;
                background-size: 200%;
                background-position: left;
                transition: 0.8s background-position ease-in-out;
                font-family: PoppinsMedium;
                font-size: var(--button);
                border: none;
                padding: 0.3rem 1rem;
                text-decoration: none;
            }
        }
        .map {
            .Gmap {
                width: 600px;
                height: 300px;
                opacity: 0.5;
                border-radius: 10px;
                transition: opacity 0.5s ease-in;
            }
            .Gmap:hover {
                opacity: 1;
            }
            .officeLoc {
                display: grid;
                grid-template-areas:
                    "oHead  cHead"
                    "hOffice  cIcon"
                    "office   cNo";
                grid-gap: 1rem;
                h5 {
                    font-size: clamp(12px, 10vw, 16px);
                    font-family: PoppinsBold;
                }
                .Ohead {
                    grid-area: oHead;
                    font-size: var(--subhead);
                    font-family: PoppinsBold;
                    justify-self: flex-start;
                }
                .hOffice {
                    grid-area: hOffice;
                    font-size: var(--subp);
                    max-width: 400px;
                }
                .office {
                    grid-area: office;
                    font-size: var(--subp);
                    max-width: 400px;
                }
                .cHead {
                    grid-area: cHead;
                    font-size: var(--subhead);
                    font-family: PoppinsBold;
                    justify-self: flex-start;
                }
                .cIcon {
                    grid-area: cIcon;
                    display: flex;
                    justify-content: space-between;
                }
                .cNo {
                    grid-area: cNo;
                    font-size: 14px;
                    display: grid;
                    grid-template-columns: 1fr 3fr;
                    text-align: left;
                    a {
                        color: white;
                        text-decoration: none;
                    }
                }
            }
            .group {
                display: grid;
                grid-template-columns: 1fr 1fr;
                text-align: left;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .carousel-caption {
        top: 34% !important;
    }
    .officeLoc {
        grid-template-areas:
            "oHead"
            "hOffice"
            "office"
            "cHead"
            "cIcon"
            "cNo" !important;
    }
    .aboutSection {
        img {
            width: 100%;
        }
    }

    .cNo {
        p {
            align-self: flex-start !important;
        }
    }
    .btns {
        .button {
            padding: 0.5rem 1rem !important;
            width: 140px !important ;
        }
    }
}
@media screen and (max-width: 540px) {
    .btns {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .light {
            margin-right: 0 !important;
        }
    }
}
@media screen and (max-width: 650px) {
    .Gmap {
        width: 400px !important;
    }
    .serviceSection {
        .section {
            .svcIcons {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}
@media screen and (max-width: 800px) {
    .aboutSection {
        flex-direction: column;
    }
    .serviceSection {
        .section {
            .abouttexts {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 930px) {
    .project {
        flex-direction: column;
        img {
            width: 100%;
        }
    }
}
@media screen and (max-width: 900px) {
    .testimonial {
        .skewed {
            clip-path: none;
            width: 100%;
            -webkit-clip-path: none;
        }
    }
    .section {
        flex-direction: column;
        gap: 1rem;
    }
}
@media screen and (max-width: 450px) {
    .Gmap {
        width: 300px !important;
        height: 150px !important;
    }
}
@media screen and (max-width: 1202px) {
    .aboutSection {
        padding: 0 3vw;
    }
    .serviceSection {
        padding-right: 3vw;
        padding-left: 3vw;
    }
    .project {
        padding: 0 3vw;
    }
    .skewed {
        padding: 0 3vw !important;
    }
    .smoke {
        padding: 0 3vw;
    }
}
