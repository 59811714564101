.App {
  text-align: center;
  font-family: Poppins;
  --orange: #f15a29;
  // home section
  --phead: clamp(10px, 4vw, 20px);
  --h3: clamp(14px, 6vw, 36px);
  --para: clamp(8px, 4vw, 18px);
  --button: clamp(10px, 4vw, 18px);
  --subhead: clamp(10px, 5vw, 24px);
  --subp: clamp(8px, 3vw, 16px);

  //other pages
  --sechead: clamp(0.8rem, 7vw, 2rem);
  --iconhead: clamp(0.5rem, 5vw, 1.5rem);
  --sech2: clamp(1.2rem, 9vw, 2.8rem);

  //project
  --phead: clamp(0.8rem, 6vw, 2rem);
  --pbtn: clamp(9px, 3vw, 18px);
  --casehead: clamp(0.8rem, 5vw, 1.7rem);
  --casep: clamp(0.5rem, 4vw, 1rem);
}
