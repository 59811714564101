.headshow {
    display: block;
}
.headhide {
    display: none;
}
.header {
    background-color: #2b2b28b6;
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
    #menuToggle {
        display: none;
    }
    .headSection {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        .logoImg {
            height: 37px;
            padding: 3px 0;
        }

        ul {
            list-style-type: none;
            margin: 0px;
            .links {
                display: flex;
                justify-content: space-between;
                gap: 1rem;
                height: 100%;
                align-items: center;
                .active {
                    li {
                        color: var(--orange);
                        font-family: PoppinsMedium;
                    }
                }
                a {
                    text-decoration: none !important;
                    padding: 0;
                    font-family: Poppins;
                    font-size: 18px;
                    transition: color 0.2s ease-in;
                }
                a:hover {
                    color: var(--orange);
                }
                li:hover {
                    color: var(--orange);
                }
                li {
                    color: white;
                    display: inline-block;
                    transition: color 0.2s ease-in;
                }
                .cBtn {
                    background-image: linear-gradient(to right, var(--orange), #b93a11, #b93a11, #b93a11);
                    color: white;
                    background-size: 200%;
                    background-position: left;
                    transition: 0.8s background-position ease-in-out;
                    font-family: Poppins;
                    font-size: 18px;
                    border: none;
                    border-radius: 5px;
                    padding: 0.3rem 1rem;
                    text-decoration: none;
                }
            }
            .nav-link {
                color: white;
            }
        }
    }
}
.accordion-item {
    background-color: #ededed;
    border: none !important;
    .accordion-header {
        font-family: Poppins;
        .accordion-button {
            text-align: right;
            font-size: 22px !important;
            background-color: #ededed !important;
            flex-direction: column;
            align-items: flex-end;
            padding: 0;
        }
        .accordion-button:hover {
            color: var(--orange);
        }
        .accordion-button::after {
            background-image: none;
            display: none;
        }
        .accordion-button:focus {
            border-color: blanchedalmond;
            box-shadow: none;
        }
        .accordion-button:not(.collapsed) {
            color: var(--orange);
        }
        .accordion-button:not(.collapsed)::after {
            background-image: none;
        }
    }
    .accordion-body {
        background-color: #ededed;
        padding: 0px;
        a {
            li {
                font-family: Poppins;
                font-size: 16px !important;
            }
            li:hover {
                color: var(--orange);
            }
        }
    }
}
@media screen and (max-width: 770px) {
    .links {
        a {
            visibility: hidden;
            display: none;
        }
    }
    .logoImg {
        align-self: center;
    }
    #menuToggle {
        display: block !important;
        position: relative;
        z-index: 1;
        -webkit-user-select: none;
        user-select: none;
    }

    #menuToggle a {
        text-decoration: none;
        color: #232323;

        transition: color 0.3s ease;
    }

    #menuToggle a:hover {
        color: tomato;
    }

    #menuToggle input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: -7px;
        left: -5px;

        cursor: pointer;

        opacity: 0;
        z-index: 2;

        -webkit-touch-callout: none;
    }

    #menuToggle span {
        display: block !important;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;

        background: #cdcdcd;
        border-radius: 3px;

        z-index: 1;

        transform-origin: 4px 0px;

        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            opacity 0.55s ease;
    }

    #menuToggle span:first-child {
        transform-origin: 0% 0%;
    }

    #menuToggle span:nth-last-child(2) {
        transform-origin: 0% 100%;
    }

    /* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
    #menuToggle input:checked ~ span {
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
        background: #232323;
    }

    /*
 * But let's hide the middle one.
 */
    #menuToggle input:checked ~ span:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }

    /*
 * Ohyeah and the last one should go the other direction
 */
    #menuToggle input:checked ~ span:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
    }

    /*
 * Make this absolute positioned
 * at the top left of the screen
 */
    #menu {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 300px;
        min-height: 100vh;
        margin: -43px 0 0 50px;
        padding: 50px;
        padding-top: 125px;
        background: #ededed;
        list-style-type: none;
        transform: translate(100%, 0%);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        .active {
            li {
                color: var(--orange);
                font-family: PoppinsMedium;
            }
        }
        a {
            visibility: visible;
            display: block;
            text-align: right;
            li {
                color: black;
            }
            li:hover {
                color: var(--orange);
            }
        }
    }

    #menu li {
        padding: 10px 0;
        font-size: 22px;
    }

    /*
 * And let's slide it in from the left
 */
    #menuToggle input:checked ~ ul {
        -webkit-font-smoothing: antialiased;
        /* to stop flickering of text in safari */
        transform-origin: 0% 0%;
        transform: translate(-100%, 0);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }
}
@media screen and (max-width: 1202px) {
    .headSection {
        padding: 10px 3vw !important;
    }
}
