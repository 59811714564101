body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"), url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "PoppinsMedium";
  src: local("PoppinsMedium"), url(./assets/fonts/Poppins-Medium.ttf) format("truetype");
  font-weight: 500;

}
@font-face {
  font-family: "PoppinsLight";
  src: local("PoppinsLight"), url(./assets/fonts/Poppins-Light.ttf) format("truetype");
  font-weight: 300;

}
@font-face {
  font-family: "PoppinsElight";
  src: local("PoppinsElight"), url(./assets/fonts/Poppins-ExtraLight.ttf) format("truetype");
  font-weight: 200;

}
