@media screen and (max-width: 1202px) {
    .service-headsection {
        padding: 90px 3vw 20px !important;
    }
}
@media screen and (max-width: 900px) {
    .service-icon {
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
    }
}
@media screen and (max-width: 650px) {
    .service-icon {
        grid-template-columns: 1fr 1fr 1fr !important;
    }
}
@media screen and (max-width: 475px) {
    .service-icon {
        grid-template-columns: 1fr 1fr !important;
    }
}
@media screen and (max-width: 400px) {
    .serviceicon-details {
        p {
            text-align: left !important;
        }
    }
}

@media screen and (max-width: 325px) {
    .service-icon {
        grid-template-columns: 1fr !important;
    }
}
.service-headsection {
    max-width: 1200px;
    min-height: 100vh;
    font-family: PoppinsMedium;
    padding: 90px 0 20px;
    margin: 0 auto;

    .servicehead {
        .headingUnderline {
            max-width: 230px;
            height: 2px;
            opacity: 1;
            margin: 0 auto;
        }
        p {
            font-family: Poppins;
        }
        h2 {
            font-size: var(--sech2);
        }
    }
    .serviceicons-section {
        min-height: 80vh;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        .service-icon {
            margin-top: 2rem;
            display: grid;
            justify-self: center;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            .Igroup {
                max-width: 300px;
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 5px;
                transition: box-shadow 0.5s ease-in-out;
                p {
                    font-size: var(--para);
                }
            }
            .Igroup:hover {
                box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
            }
            .active{
                box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
            }
        }
        .serviceicon-details {
            min-height: 30vh;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;
            padding: 20px;

            h3 {
                justify-self: flex-end;
                font-size: var(--sechead);
            }
            p {
                font-size: var(--para);
                text-align: justify !important;
                justify-self: flex-start;
            }
        }
        .viewservicebody {
            position: relative;
            animation: animservice 0.8s ease-in-out;
        }
        @keyframes animservice {
            from {
                opacity: 0;
                top: 20px;
            }
            to {
                opacity: 1;
                top: 0px;
            }
        }
    }
}
