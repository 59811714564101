@media screen and (max-width: 400px) {
  .aboutbody-details {
    p {
      text-align: left !important;
    }
  }
}
@media screen and (max-width: 1100px) {
  .about-headsection {
    padding: 90px 3vw 20px !important;
  }
}
.about-headsection {
  max-width: 1200px;
  min-height: 100vh;
  font-family: PoppinsMedium;
  padding: 90px 0 20px;
  margin: 0 auto;

  .abouthead {
    .headingUnderline {
      max-width: 150px;
      height: 2px;
      opacity: 1;
      margin: 0 auto;
    }
    p {
      font-family: Poppins;
    }
    h2 {
      font-size: var(--sech2);
    }
  }
  .aboutbody-section {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    .about-Ws {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-self: center;
      .Wgroup {
        max-width: 400px;
        padding: 20px 10px;
        border-radius: 5px;
        transition: box-shadow 0.5s ease-in-out;
        h4 {
          margin: 2rem 0 0 0;
          font-size: var(--iconhead);
        }
      }
      .Wgroup:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
      }
      .active {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
      }
    }
    .viewaboutbody {
      position: relative;
      animation: animabout 0.8s ease-in-out;
    }
    @keyframes animabout {
      from {
        opacity: 0;
        top: 20px;
      }
      to {
        opacity: 1;
        top: 0px;
      }
    }
    .aboutbody-details {
      max-width: 1200px;
      margin: 0 auto;
      min-height: 30vh;
      display: flex;
      flex-direction: column;
      padding: 20px;
      width: 100%;
      h3 {
        margin-bottom: clamp(0.8rem, 2vw, 2rem);
        font-size: var(--sechead);
        justify-self: flex-end;
      }
      p {
        font-size: var(--para);
        text-align: justify !important;
        justify-self: flex-start;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .about-headsection {
    .abouthead {
      .headingUnderline {
        max-width: 100px !important;
      }
    }
    .about-Ws {
      grid-template-columns: 1fr !important;
    }
  }
}

.topText {
  text-align: justify;
  text-justify: inter-word;
  margin-top: 50px;
}
