@media screen and (max-width:1200px) {
    .project-mainsection {
        padding: 0 3vw !important;
    }
}
@media screen and (max-width: 780px) {
    .projects {
        grid-template-columns: 1fr !important;
        .wellcare {
            height: 100% !important;
        }
        .byrebarn {
            height: 100% !important;
        }
    }
}
@media screen and (max-width: 400px) {
    .wellcareicon {
        height: 100px !important;
    }
    .byrebarnicon {
        height: 80px !important;
    }
    .shipexicon {
        height: 100px !important;
    }
    .tripmochaicon {
        height: 100px !important;
    }
}
.project-mainsection {
    .project-headsection {
        max-width: 1200px;
        min-height: 100vh;
        font-family: PoppinsMedium;
        padding: 90px 0 20px;
        margin: 0 auto;

        .projecthead {
            .headingUnderline {
                max-width: 150px;
                height: 2px;
                opacity: 1;
                margin: 0 auto;
            }
            p {
                font-family: Poppins;
            }
            h2 {
                font-size: var(--sech2);
            }
        }
        .projectbody-section {
            min-height: 80vh;
            display: flex;
            flex-direction: column;
            .projects {
                margin-top: 2rem;
                display: grid;
                min-height: 75vh;
                grid-template-columns: 1fr 1fr;
                justify-self: center;
                p {
                    font-size: var(--subp) !important;
                }
                .pBtn {
                    background-image: linear-gradient(to right, #383f50, #383f50, #22252c, #22252c);
                    background-color: #383f50;
                    color: white;
                    background-size: 200%;
                    background-position: left;
                    transition: 0.8s background-position ease-in-out;
                    font-family: PoppinsMedium;
                    font-size: var(--pbtn);
                    border: none;
                    padding: 0.3rem 1rem;
                    text-decoration: none;
                }
                .pBtn:hover {
                    background-position: right;
                }
                .byrebarn {
                    background-color: #eac492;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    height: 100%;
                    .byrebarnicon {
                        height: 120px;
                        padding: 1rem;
                        align-self: center;
                    }
                    .byrebarn_details {
                        color: #383f50;
                        padding: 0 2rem;
                    }
                }
                .wellcare {
                    background-color: #e8a8aa;
                    flex-direction: column;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    height: 100%;
                    .wellcareicon {
                        height: 150px;
                        padding: 1.3rem;
                        align-self: center;
                    }
                    .wellcare_details {
                        color: #383f50;
                        padding: 0 2rem;
                    }
                }

                .tripmocha {
                    background-color: #8a9683;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    height: 100%;
                    .tripmochaicon {
                        height: 130px;
                        padding: 1rem;
                        align-self: center;
                    }
                    .tripmocha_details {
                        color: #383f50;
                        padding: 0 2rem;
                    }
                }
                .shipex {
                    background-color: #cec4c4;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    height: 100%;
                    .shipexicon {
                        height: 130px;
                        padding: 1rem;
                        align-self: center;
                    }
                    .shipex_details {
                        color: #383f50;
                        padding: 0 2rem;
                    }
                }
                .project-details {
                    text-align: left;
                    h3 {
                        font-family: PoppinsBold;
                        font-size: var(--phead) !important;
                    }
                }
            }
        }
    }
}
